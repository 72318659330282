import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ConfigProvider from 'cs-components/dist/es/ConfigProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00DA39',
          colorPrimaryBorder: '#00DA39AA',
          colorPrimaryHover: '#00DA39',
          fontFamily: '"Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        },
        components: {
          Tabs: {
            inkBarColor: '#00DA39',
            itemActiveColor: '00DA39',
            itemHoverColor: '#00DA39AA',
            itemSelectedColor: '#00DA39'
          },
          Pagination: {
            colorPrimary: '#00DA39',
            colorPrimaryBorder: '#00DA39AA',
            colorPrimaryHover: '00DA39'
          },
          Input: {
            activeBorderColor: '00DA39',
            hoverBorderColor: '00DA39',
          },
        },
      }}>
        <App />
      </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
