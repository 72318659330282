import React from "react";
import './OrgFooter.scss';

//need to integrate API

interface FooterItem {
    data: string;
    link?: string;
}

const OrgFooter = () => {
    const footerData: { [key: string]: FooterItem[] } = {
        "Headquarters" : [
            { data: "1220 Waterway Blvd.", link: "" },
            { data: "Indianapolis, IN 46202", link: "" }
        ],
        "India" : [
            { data: "Block D, 1st Flr SG No. 559/4", link: "" },
            { data: "Udayampalayam Road,", link: "" },
            { data: "Nava India, Coimbatore-28, Tamil Nadu", link: "" }
        ],
        "Why ConverSight?" : [],
        "About" : [],
        "Platform" : [
            { data: "AI Workbench", link: "https://conversight.ai/ai-workbench/" },
            { data: "Data Sync", link: "https://conversight.ai/data-sync/" },
            { data: "Data Visualization", link: "https://conversight.ai/data-visualization/" },
            { data: "Data Engineering", link: "https://conversight.ai/data-engineering/" },
            { data: "Data Exploration", link: "https://conversight.ai/data-exploration/" },
            { data: "Embedded Analytics", link: "https://conversight.ai/embedded-analytics/" },
            { data: "Trust Center", link: "https://conversight.ai/platform/trust-center/" }
        ],
        "Solutions" : [],
        "By Function" : [
            { data: "Supply Chain", link: "https://conversight.ai/supply-chain/" },
            { data: "Services", link: "https://conversight.ai/smart-service-solutions/" },
            { data: "Sales", link: "https://conversight.ai/sales-solutions/" },
            { data: "Procurement", link: "https://conversight.ai/procurement-solutions/" },
            { data: "Finance", link: "https://conversight.ai/finance-solutions/" }
        ],
        "By Industry" : [
            { data: "Manufacturing", link: "https://conversight.ai/manufacturing/" },
            { data: "Distribution", link: "https://conversight.ai/distribution/" },
            { data: "Retail and E-Commerce", link: "https://conversight.ai/retail-e-commerce/" }
        ],
        "By Role" : [
            { data: "Business Leader", link: "https://conversight.ai/business-leader/" },
            { data: "Business Analyst", link: "https://conversight.ai/business-analyst/" },
            { data: "Data Leader", link: "https://conversight.ai/data-leader/" },
            { data: "Data Scientist", link: "https://conversight.ai/data-scientist/" },
            { data: "Data Analyst", link: "https://conversight.ai/data-analyst/" },
            { data: "SaaS Product Leader", link: "https://conversight.ai/saas-product-leader/" }
        ],
        "Resources" : [
            { data: "Resource Hub", link: "https://conversight.ai/resources/" },
            { data: "Blog", link: "https://conversight.ai/blog/" },
            { data: "Case Studies", link: "https://conversight.ai/resources/casestudies/" },
            { data: "Use Cases", link: "https://conversight.ai/use-cases/" },
            { data: "Whitepapers", link: "https://conversight.ai/resources/whitepapers/" },
            { data: "Press", link: "https://conversight.ai/resources/press/" },
            { data: "Videos", link: "https://conversight.ai/resources/videos/" },
            { data: "Webinars", link: "https://conversight.ai/resources/webinars/" }
        ]
    };

    return (
        <div className="org-footer">
            <div>
                <div className="org-footer-container">
                    <div>
                        <img src='https://conversight.ai/wp-content/uploads/2022/04/Primary_logo.svg' />
                        <div className="org-footer-des font-opacity">Actionable Insights. Instantly. Anywhere.</div>
                    </div>
                    {Object.keys(footerData).map((key) => (
                        <div key={key} className="footer-separation">
                            <div className={`org-footer-header ${key.includes('By') ? (key.includes('By Role') ? 'additional-size color-green' : 'color-green') : (key.includes('Platform') ?'font-green':'')}`}>{key}</div>
                            {footerData[key].map((item, index) => (
                                <div key={index}>
                                    {item.link ? (
                                        <a href={item.link} className="font-opacity footer-link">{item.data}</a>
                                    ) : (
                                        <div className="font-opacity">
                                            {item.data}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="communication-container">
                    <div className="email-container">
                        E: <a href="" className="info-link">info@conversight.ai</a>
                    </div>
                    <div className="social-media">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 0.000234375C4.48 0.000234375 0 4.50023 0 10.0502C0 15.0902 3.69 19.2502 8.51 19.9802V12.7202H6.04V10.0802H8.51V8.32023C8.51 5.41023 9.92 4.13023 12.33 4.13023C13.48 4.13023 14.09 4.22023 14.38 4.25023V6.56023H12.74C11.72 6.56023 11.36 7.53023 11.36 8.63023V10.0702H14.35L13.94 12.7102H11.35V19.9902C16.23 19.3202 20 15.1302 20 10.0402C20 4.49023 15.52 -0.00976562 10 -0.00976562V0.000234375Z" fill="#00DA49"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M18.18 0H1.82C0.81 0 0 0.81 0 1.82V18.18C0 19.18 0.81 20 1.82 20H18.18C19.18 20 20 19.19 20 18.18V1.82C20 0.82 19.19 0 18.18 0ZM6.32 16.36H3.64V7.73H6.32V16.36ZM4.95 6.5C4.09 6.5 3.39 5.8 3.39 4.94C3.39 4.08 4.09 3.38 4.95 3.38C5.81 3.38 6.51 4.08 6.51 4.94C6.51 5.8 5.81 6.5 4.95 6.5ZM16.36 16.36H13.68V12.16C13.68 11.16 13.66 9.87 12.29 9.87C10.92 9.87 10.68 10.96 10.68 12.09V16.36H8V7.73H10.57V8.91H10.61C10.97 8.23 11.84 7.52 13.15 7.52C15.87 7.52 16.37 9.31 16.37 11.63V16.36H16.36Z" fill="#00DA49"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 16" fill="none">
                        <path d="M20.0002 1.89C19.2602 2.21 18.4702 2.43 17.6402 2.53C18.4902 2.03 19.1402 1.24 19.4402 0.3C18.6502 0.76 17.7702 1.1 16.8302 1.28C16.0802 0.49 15.0202 0 13.8402 0C11.5702 0 9.74024 1.81 9.74024 4.04C9.74024 4.36 9.78023 4.67 9.85023 4.96C6.44023 4.79 3.42023 3.18 1.39023 0.74C1.04023 1.34 0.840235 2.03 0.840235 2.77C0.840235 4.17 1.56023 5.41 2.66023 6.13C1.99023 6.11 1.35023 5.93 0.800234 5.62V5.67C0.800234 7.63 2.21023 9.26 4.09023 9.63C3.75023 9.72 3.38023 9.77 3.01023 9.77C2.75023 9.77 2.49023 9.74 2.24023 9.7C2.76023 11.3 4.28023 12.47 6.07023 12.51C4.67023 13.59 2.90023 14.24 0.970234 14.24C0.640234 14.24 0.310234 14.22 -0.00976562 14.18C1.81023 15.33 3.96024 15.99 6.28024 15.99C13.8302 15.99 17.9502 9.84 17.9502 4.5C17.9502 4.33 17.9502 4.15 17.9402 3.98C18.7402 3.41 19.4402 2.7 19.9902 1.89H20.0002Z" fill="#00DA49"/>
                    </svg>
                    </div>
                </div>
                <div className="copy-rights-container">
                    <div className="copy-rights font-opacity">
                        © 2023 Conversight. All Rights Reserved.
                    </div>
                    <div className="terms-and-condition-container font-opacity">
                        <a href="https://conversight.ai/request-demo/" className="font-opacity footer-link">Schedule Demo</a> &nbsp;/&nbsp;&nbsp;
                        <a href="https://conversight.ai/privacy-policy/" className="font-opacity footer-link">Privacy Policy</a> &nbsp;/&nbsp;&nbsp;
                        <a href="https://conversight.ai/terms-and-conditions/" className="font-opacity footer-link">Terms of Use</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrgFooter;
