
import axios from "axios"

const env = {
    STAGING: {
        API_URL: 'api.staging.conversight.ai',
        AUTHENICATOR: 'authenticator.staging.conversight.ai'
    },
    PRODUCTION: {
        API_URL: 'api3.conversight.ai',
        AUTHENICATOR: 'authenticator3.conversight.ai'
    }
}

const protocol = 'https://';
const api = '/api/v1'

//change the "PROD" env when publish
const { API_URL, AUTHENICATOR } = true
    ? env.PRODUCTION
    : env.PRODUCTION;


export const validateEmail = async payload => {
    return await axios.get(protocol + AUTHENICATOR + api + `/user` + `?email=` + `${payload}`)
}

export const personalData = async () => {
    return await axios.get(protocol + AUTHENICATOR + api + '/trialSettings?action=signup')
}

export const getResourceDetails = async payload => {
    return await axios.get(`${protocol}${API_URL}/resource-engine/resource/marketPlace/docs?id=${payload}`)
}

export const getSignupDetails = async payload => {
    return await axios.post(`${protocol + AUTHENICATOR + api}/signUp?source=marketPlace&productID=${payload.id}`, { data: payload.details })
}