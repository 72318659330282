import React from 'react';
import banner from './resource/banner.svg';
import './App.scss';
import LandingPage from './components/LandingPage';
import AppFilterComponent from './components/AppFilterComponent/AppFilterComponent';
import bannerImage from './resource/banner_image_1.svg';
import Button from 'cs-components/dist/es/Button';
import BottomBackground from './resource/Bottom_background.svg';
import IconCtaAthena from 'cs-components/dist/es/IconCtaAthena';
import Card from 'cs-components/dist/es/Card';
import { ArrowRightOutlined } from '@ant-design/icons';
import OrgFooter from './components/OrgFooter/OrgFooter';
// import video from './resource/animation.mp4'


function App() {
  return (
    <div className='body'>
      <div className="App">
        <div className='banner-background'>
          <div className='banner-container'>
            <div className='header'>
              <div><img src='https://conversight.ai/wp-content/uploads/2022/04/Primary_logo.svg' /></div>
              <div className='header-resources'>
                <div><a href='https://conversight.ai/why-conversight/' target='_blank' className='header-text'>Why ConverSight?</a></div>
                <div><a href='https://conversight.ai/platform' target='_blank' className='header-text'>Platform</a></div> {/*Need to change */}
                <div><a href='https://community.conversight.ai/' target='_blank' className='header-text'>Our Community</a></div> {/*Need to change */}
                <div><a href='https://academy.conversight.ai/' target='_blank' className='header-text'>Resources</a></div> {/*Need to change */}
                <div><a href='https://conversight.ai/about-conversight/' target='_blank' className='header-text'>About</a></div> {/*Need to change */}
              </div>
              <div className='header-login'>
                <div><a href='https://conversight.navattic.com/e976089g' target='_blank'><Button type='primary' className='header-text-btn header-button'>Explore Product</Button></a></div>
                <div><a href='https://conversight.ai/request-demo/' target='_blank'><Button type='primary' className='header-text-btn header-button'>Request a Demo</Button></a></div>
                <div><a href='https://app.conversight.ai/login' target='_blank' className='header-text'>Login</a></div>
              </div>
            </div>
            <video id="background-video" className='banner-video' autoPlay muted loop>
              {/* <source src="http://192.168.0.116:8080/Animation.mov" type='video/quicktime; codecs="hvc1"' /> */}
              <source src="https://s3.amazonaws.com/docs.conversight.ai/videos/Animation.mov" type="video/mp4" />
              <source src="https://s3.amazonaws.com/docs.conversight.ai/videos/Transparent.webm" type="video/webm" />
              {/* <source src="http://192.168.0.116:8080/transparant.mp4" type="video/mp4" /> */}
            </video>
            <div className='banner-content'>
              <div className='marketsspace-banner'>
                Marketspace
              </div>
              <div className='banner-body'>
                Discover, Connect, and Thrive in the ConverSight Marketspace
              </div>
              {/* <Button type='primary' className='resource-btn'>Explore Our Resources Now</Button> */}
            </div>
          </div>
        </div>
        <LandingPage />
        <div className='footer'>
          <div className='footer-container'>
            <div className='footer-left'>
              <div className='footer-content'>
                <IconCtaAthena />
                <div className='footer-main-content'>
                  We humanize interactions between systems and its users
                </div>
                <div className='footer-des'>
                  We deliver smart solutions that think and talk
                </div>
              </div>
            </div>
            <div className='footer-card'>
              <a href='https://conversight.ai/request-demo' className='hyperlink'>
                <div className='card-component'>
                  <img src='https://conversight.ai/wp-content/uploads/2022/04/cta-arrow-right-square.svg' />
                  <div className='card-header'>Start <br />now</div>
                  <div className='card-content'>
                    Make accurate decisions through simple dialogue
                  </div>
                  <div className='arrow-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="16" viewBox="0 0 23 16" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M22.48 7.7702C22.48 8.2202 22.11 8.5902 21.66 8.5902H0.82C0.37 8.5902 0 8.2202 0 7.7702C0 7.3202 0.37 6.9502 0.82 6.9502H21.66C22.11 6.9502 22.48 7.3202 22.48 7.7702Z" fill="#00DA49"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.1301 0.24C14.4501 -0.08 14.9701 -0.08 15.2901 0.24L22.2401 7.19C22.5601 7.51 22.5601 8.03 22.2401 8.35L15.2901 15.3C14.9701 15.62 14.4501 15.62 14.1301 15.3C13.8101 14.98 13.8101 14.46 14.1301 14.14L20.5001 7.77L14.1301 1.4C13.8101 1.08 13.8101 0.56 14.1301 0.24Z" fill="#00DA49"/>
                    </svg>
                  </div>
                </div>
              </a>
              <a href='https://conversight.ai/request-demo' className='hyperlink'>
                <div className='card-component'>
                  <img src='https://conversight.ai/wp-content/uploads/2022/04/cta-user.svg' />
                  <div className='card-header'>Request <br />a demo</div>
                  <div className='card-content'>
                  Experience a true self-service business intelligence tool
                  </div>
                  <div className='arrow-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="16" viewBox="0 0 23 16" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M22.48 7.7702C22.48 8.2202 22.11 8.5902 21.66 8.5902H0.82C0.37 8.5902 0 8.2202 0 7.7702C0 7.3202 0.37 6.9502 0.82 6.9502H21.66C22.11 6.9502 22.48 7.3202 22.48 7.7702Z" fill="#00DA49"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.1301 0.24C14.4501 -0.08 14.9701 -0.08 15.2901 0.24L22.2401 7.19C22.5601 7.51 22.5601 8.03 22.2401 8.35L15.2901 15.3C14.9701 15.62 14.4501 15.62 14.1301 15.3C13.8101 14.98 13.8101 14.46 14.1301 14.14L20.5001 7.77L14.1301 1.4C13.8101 1.08 13.8101 0.56 14.1301 0.24Z" fill="#00DA49"/>
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className='second-footer'>
          <OrgFooter />
        </div>
      </div>
    </div>
  );
}

export default App;
