import React, { useState, useMemo } from 'react';
import './ResourceCard.scss';
import Rate from 'cs-components/dist/es/Rate';
import Drawer from 'cs-components/dist/es/Drawer';

import HeartFilled from '@ant-design/icons/HeartFilled';
import HeartOutlined from '@ant-design/icons/HeartOutlined';
import { ArrowLeftOutlined } from '@ant-design/icons';
import AppDetail from '../AppDetail/AppDetail';
import ProfileBg from '../../resource/profileBg.svg'
import Tooltip from 'cs-components/dist/es/Tooltip';

//need to integrate API

type ResourceCardProps = {
  data: any;
  isFavNeeded?: boolean;
};

const ResourceCard: React.FC<ResourceCardProps> = (props) => {
  const { data } = props;
  const isFavNeeded = props.isFavNeeded !== undefined ? props.isFavNeeded : true;
  const [showModal, setShowModal] = useState(false);
  const [resourceId, setResourceId] = useState(null);

  const FavoriteIcon = ({ resourceId }: any) => {
    //   const storeFavoriteResource = () => {
    //     const data = { id: resourceId };
    //     props.saveFavoriteResource(data, (err: any) => {
    //       if (!err) {
    //         message.success('Added Resource to Favorites');
    //       } else {
    //         message.error('Favorite Resources is not updated!');
    //       }
    //     });
    //   };

    const removeFavoriteResource = () => {
      const data = { id: resourceId };
      // props.deleteFavoriteResource(data, (err: any) => {
      //   if (!err) {
      //     message.warning('Removed Resource from Favorites');
      //   } else {
      //     message.error('Favorite Resources is not updated!');
      //   }
      // });
    };

    const [isFavorite, setIsFavorite] = useState(data?.isFavourite);

    //   const handleFavoriteToggle = (e: React.MouseEvent<HTMLDivElement>) => {
    //     e.stopPropagation();
    //     if (!isFavorite) {
    //       storeFavoriteResource();
    //     } else {
    //       removeFavoriteResource();
    //     }
    //     setIsFavorite(!isFavorite);
    //   };

    return (
      // <div onClick={handleFavoriteToggle} className="favorite-icon" data-testid="favorite-icon">
      //   {isFavorite ? <HeartFilled style={{ fontSize: '20px' }} /> : <HeartOutlined style={{ fontSize: '20px' }} />}
      // </div>
      <div className="favorite-icon" data-testid="favorite-icon">
        {isFavorite ? <HeartFilled style={{ fontSize: '20px' }} /> : <HeartOutlined style={{ fontSize: '20px' }} />}
      </div>
    );
  };

  const DrawerMemo = useMemo(() => {
    return (
      <Drawer
        width="100%"
        // rootStyle={{ top: 62 }}
        drawerStyle={{ height: '100vh', fontFamily: 'Montserrat' }}
        placement="right"
        open={showModal}
        onClose={() => setShowModal(false)}
        title={
          <span
            onClick={() => {
              setShowModal(false);
            }}
            style={{ cursor: 'pointer', fontSize: '15px' }}
          >
            <ArrowLeftOutlined /> &emsp;Back
          </span>
        }
        closable={false}
        destroyOnClose={true}
      >
        <AppDetail resourceId={resourceId} categoriesAndFeatures={undefined} />
        {/* <AppDetail /> */}
      </Drawer>
    );
  }, [showModal]);


  const handleOpen = () => {
    setShowModal(true);
    setResourceId(data?.id);
  };

  return (
    <>
      <div
        className="resource-card"
        onClick={() => {
          handleOpen();
        }}
      >
        <div>
          <div className="resource-image-fav">
            <div className='profile'>
              <img src={data?.profileIcon} alt="resource_image" className="resource-image-content" />
            </div>
            {isFavNeeded ? <FavoriteIcon resourceId={data?.id} /> : ''}
          </div>
          <div className="resource-title">{data?.name ? data?.name : data?.displayName}</div>
          <div className="resource-tagline"><Tooltip title={data?.tagline}>{data?.tagline}</Tooltip></div>
        </div>
        <div className="resource-rating">
          <div className='rating-block'>
            <Rate className="custom-rate" disabled value={data?.rating} 
            />
            {!(data?.rating === -1) ? <div className="rating-text">{data?.totalComments}</div> : <div className="rating-text">{'N/A'}</div>}
          </div>
          <div className='explore-content'>Explore Now</div>
        </div>
      </div>
      {DrawerMemo}
    </>
  );
};

export default ResourceCard;