import React, { useEffect, useMemo, useState } from 'react';
import './LandingPage.scss';
import ResourceCard from './ResourceCard/ResourceCard';
import ContentBg from '../resource/content-bg.svg';
import Input from 'cs-components/dist/es/Input';
import { SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import axios from 'axios';
import AppFilterComponent from './AppFilterComponent/AppFilterComponent';
import Spin from 'cs-components/dist/es/Spin';

type ButtonStates = {
  [key: string]: boolean;
};

const SearchResourcesbyName = (data: { filterby: any; searchTerm: any; limit: any; offset: any; }) => {
  const { filterby, searchTerm, limit, offset } = data;
  const url = `https://api3.conversight.ai/resource-engine/resource/marketPlace/${filterby}?searchTerm=${searchTerm}`;

  return axios.get(url);
};

const getPopularResources = () => {
  const url = `https://api3.conversight.ai/resource-engine/resource/marketPlace/popularResources`;

  return axios.get(url);
};

const getFilterByCategoriesAndFeatures = (data: { filterby: any; categories: any; features: any; limit: any; offset: any; }) => {
  const { filterby, categories, features, limit, offset } = data;
  const url = `https://api3.conversight.ai/resource-engine/resource/marketPlace/${filterby}?categories=${categories}&features=${features}&limit=${limit}&offset=${offset}`;
  return axios.get(url);
};

const LandingPage = () => {
  const [isGeneralFiltered, setIsGeneralFiltered] = useState(false);
  const [popularfilterData, setPopularFilterData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [isPublishedFiltered, setIsPublishedFiltered] = useState(false);
  const [publishedFilterData, setPublishedFilterData] = useState([]);
  const [isSubscriptionFiltered, setIsSubscriptionFiltered] = useState(false);
  const [subscribedfilterData, setSubscribedFilterData] = useState([]);
  const [buttonsActive, setButtonsActive] = useState<ButtonStates>({
    AllRes: true,
    MyRes: false,
    MyFav: false,
  });

  const limit = 50;
  const currentPage = 1;
  const [popularRes, setPopularRes] = useState([]);

  const generalAppData = isGeneralFiltered ? [{ items: popularfilterData }] : [{ items: popularRes }];


  useEffect(() => {
    fetchPopularRes();
  }, []);

  const fetchPopularRes = () => {
    const data = { limit: limit, offset: (currentPage - 1) * limit };
    getPopularResources()
      .then((response) => {
        setPopularRes(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSearch = (e: any) => {
    const searchTerm = e.target.value.trim();

    if (searchTerm === '') {
      setIsGeneralFiltered(false);
      return;
    }

    const data = {
      filterby: 'search',
      searchTerm: searchTerm,
      limit: limit,
      offset: (currentPage - 1) * limit,
    };

    SearchResourcesbyName(data)
      .then((response) => {
        setIsGeneralFiltered(true);
        setPopularFilterData(response.data.data.results);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (e: any) => {
    handleSearch(e);
  };

  const debouncedResults = useMemo(() => {
    // To clean up any side effects from debounce when our component gets unmounted
    return debounce(handleChange, 300);
  }, []);

  const handleGetCategoriesandFeatuesResults = (resultsData: any) => {
    getFilterByCategoriesAndFeatures(resultsData)
      .then((response) => {
        setLoading(false)
        if (buttonsActive.AllRes) {
          setIsGeneralFiltered(true);
          setPopularFilterData(response?.data?.data?.results);
        } else if (buttonsActive.MyRes) {
          setIsSubscriptionFiltered(true);
          setSubscribedFilterData(response?.data?.data?.results);
        }
      })
  };

  const handlefilterValues = (value: any) => {
    setLoading(true)
    const generateResults = (filterby: string) => {
      return {
        categories: value?.Category ? value.Category.join(',') : '',
        features: value?.Features ? value.Features.join(',') : '',
        filterby: filterby,
        limit: limit,
        offset: (currentPage - 1) * limit,
      };
    };

    if (buttonsActive.AllRes) {
      handleGetCategoriesandFeatuesResults(generateResults('search'));
    } else if (buttonsActive.MyRes) {
      const publishedData = {
        categories: value?.Category ? value.Category.join(',') : '',
        features: value?.Features ? value.Features.join(',') : '',
        filterby: 'published',
        limit: limit,
        offset: (currentPage - 1) * limit,
      };
      getFilterByCategoriesAndFeatures(publishedData)
        .then((response) => {
          setIsPublishedFiltered(true);
          setPublishedFilterData(response?.data?.data?.results);

          handleGetCategoriesandFeatuesResults(generateResults('subscriptions'));
        })
    } else if (buttonsActive.MyFav) {
      // TODO: Need to discuss
    }
  };

  return (
    <>
      <div className='marketsspace-content'>
        <div className="left-section">
          <AppFilterComponent
            getFilterValues={handlefilterValues}
            activeButtons={buttonsActive}
            setAllResFltr={setIsGeneralFiltered}
            setMyResFltr={setIsSubscriptionFiltered}
          />
        </div>
        <div className='marketsspace-entire-card'>
          <div className='landing-page'>
            <div className='entire-card-title'>
              <div className='entire-card-header'>
                Explore, Create, Publish and Buy Resources
              </div>
              <div className='content-bg-img'>
                <img src={ContentBg} className='content-bg' />
                <div className="search-input-theme">
                  <Input
                    prefix={<SearchOutlined className="search-icon" />}
                    className="search-input-inside"
                    placeholder='Search'
                    onChange={(e) => {
                      e.persist();
                      debouncedResults(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              {generalAppData?.map((group, groupIndex) => {
                if (group.items.length === 0) {
                  return (<div key={groupIndex} className="no-data-found">{popularRes.length === 0 || loading ? <Spin tip="Loading..." size="large" /> : 'Sorry, no resources match your search.'}</div>)
                }
                else {
                  return (
                    <div key={groupIndex} className='marketsspace-card'>
                      {group.items.map((item: any) => (
                        <ResourceCard data={item} key={item?.id} isFavNeeded={false} />
                      ))}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>

    </>

  );
};

export default LandingPage;