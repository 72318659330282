import Checkbox from 'cs-components/dist/es/Checkbox';
import Collapse from 'cs-components/dist/es/Collapse';

import React, { useState } from 'react';
import './AppFilterComponent.scss';

type CheckBoxProps = {
  options: Array<{
    id: string;
    name: string;
  }>;
  values?: Array<string>;
  onChange?: any;
  itemsToShow?: number;
  facetDetails?: any;
  fieldProps?: any;
};

const CheckBoxTemplate: React.FC<CheckBoxProps> = ({ options, values, onChange, fieldProps }) => {
  const [selectedTags, setSelectedTags] = useState<Array<string>>(values || []);

  const handleChange = (event: any) => {
    const nextSelectedTags: any = event.target.checked ? [...selectedTags, event.target.value] : selectedTags.filter((t) => t !== event.target.value);
    setSelectedTags(nextSelectedTags);
    onChange?.(nextSelectedTags);
  };

  return (
    <div className="filter-option">
      <div>
        <div className='cs-collapse-header-text'>
          {fieldProps.id}
        </div>
        <div style={{ position: 'relative' }}>
          <div className="values">
            {options?.map((item, index) => {
              return (
                <div className="item" key={index}>
                  <Checkbox checked={selectedTags.includes(item.id)} value={item.id} onChange={(e) => handleChange(e)} className='filter-checkbox'>
                    {item.name}
                  </Checkbox>
                </div>
              );
            })}
          </div>
        </div>
        </div>
    </div>
  );
};

export default CheckBoxTemplate;
